import { hot } from 'react-hot-loader/root';
import * as React from 'react';
import { ConnectedRouter } from 'react-router-redux';
import { Route } from 'react-router';

import { Layout } from './components/Layout';
import { Audits } from './containers/Audits';
import { AuditsAll } from './containers/AuditsAll';
import { AuditsCurrent } from './containers/AuditsCurrent';
import { AuditsFailed } from './containers/AuditsFailed';
import Callback from './containers/Callback';
import DeviceDetail from './containers/DeviceDetail';
import Devices from './containers/Devices';
import { Emails } from './containers/Emails';
import Home from './containers/Home';
import Jobs from './containers/Jobs';
import { LiteOrgsReport } from './containers/LiteOrgsReport';
import { OrgDetail } from './containers/OrgDetail';
import Orgs from './containers/Orgs';
import Reports from './containers/Reports';
import { Users } from './containers/Users';
import { AuthInterop } from './containers/AuthInterop';

const Root = ({ history }) => (
  <ConnectedRouter history={history}>
    <Layout>
      <Route exact path="/" component={Home} />
      <Route exact path="/devices" component={Devices} />
      <Route exact path="/devices/:id" component={DeviceDetail} />
      <Route exact path="/orgs" component={Orgs} />
      <Route exact path="/orgs/:id" component={OrgDetail} />
      <Route exact path="/users" component={Users} />
      <Route exact path="/reports" component={Reports} />
      <Route exact path="/reports/lite-orgs" component={LiteOrgsReport} />
      <Route exact path="/audits" component={Audits} />
      <Route exact path="/audits/all" component={AuditsAll} />
      <Route exact path="/audits/current" component={AuditsCurrent} />
      <Route exact path="/audits/failed" component={AuditsFailed} />
      <Route exact path="/emails" component={Emails} />
      <Route exact path="/jobs" component={Jobs} />
      <Route exact path="/authinterop" component={AuthInterop} />
    </Layout>
  </ConnectedRouter>
);

export default hot(Root);
